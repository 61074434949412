@import '../vendors/bootstrap/functions';
@import '../variables';
@import '../vendors/bootstrap/mixins';

.cms-no-route {

  .page-main {
    max-width: none;
    padding: 0;
    text-align: center;
    font-size: 1.8rem;

    .intro-404 {
      padding: 7.6rem $spacer 1.8rem !important;
      background: transparent linear-gradient(180deg, rgba(237, 237, 237, 1) 0%, rgba(237, 237, 237, 1) 100%) 0% 0% no-repeat padding-box;
    }

    figure {
      margin-bottom: 0;
    }

    img {
      width: 100%;
    }
  }
}

h1 {
  margin-bottom: 0.8rem;
  font-size: 3.4rem;
}
